.link {
    width: 100%;
    padding: 10px 0;
}

.horizontalLine {
    border: 0.8px solid #c5aa5f;
    margin: 0;
}

.linkItemContainer {
    display: flex;
    justify-content: space-between;
}

.href {
    display: flex;
}

.href a {
    text-decoration: none;
    color: inherit;
    text-align: right;
}

.href img {
    width: 20px;
    margin-left: 5px;
}

@media screen and (min-width: 768px) {

    .link {
        width: 100%;
        padding: 10px 0 20px;
    }

    .linkItemContainer {
        margin: 15px 0;
    }


    .href img {
        width: 30px;
        margin-left: 15px;
    }

    .link p {
        font-size: 28px;
    }

    .link p:first-child {
        font-weight: 600;
    }

    .href a:hover {
        color: #c5aa5f;
    }
}