.header {
    position: fixed;
    width: 100%;
    z-index: 2;
    transition: transform 1s ease-in-out;
}

.header.notsticky {
    transform: translate(0, -180px);
}

.header.sticky {
    transform: translate(0, 0px);
}

.headerContainer {
    position: relative;
    padding: 33px 10px;
    display: flex;
    align-items: center;
}

.menuButton {
    position: absolute;
    z-index: 2;
    transform: scale(0.5);
    cursor: pointer;
}

.menuButton:hover {
    opacity: 0.5;
}

.nav .closeButton {
    top: 25px;
    left: unset;
    right: 25px;
    transform: scale(0.75);
}

.tvLogo {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    height: 22px;
    width: 227px;
}

.nav {
    width: 100vw;
    height: 100vh;
    position: relative;
    background-color: #312f2b;
    z-index: 3;
    display: flex;
    position: absolute;
    top: 0;
    align-items: center;
    justify-content: center;
}

.navContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.navContainer button {
    color: #fff;
    font-size: 28px;
    margin: 8px;
    background: none;
    border: none;
    line-height: 1;
    position: relative;
}

@media screen and (min-width: 768px) {

    .navContainer button {
        font-size: 60px;
        margin: 10px;
    }

    .navContainer button::after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: #c5aa5f;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
    }

    .navContainer button:hover::after {
        transform: scaleX(1);
        transform-origin: bottom left;
    }

    .nav .closeButton,
    .menuButton {
        transform: scale(1);
    }

}

@media screen and (min-width: 1024px) {

    .headerContainer {
        padding: 70px;
    }

    .tvLogo {
        height: 40px;
        width: 420px;
    }

    .navContainer button {
        font-size: 70px;
        margin: 30px;
    }

}