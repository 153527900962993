body {
  overscroll-behavior: none;
  height: 100vh;
  background-color: #eeece8;
  color: #101010;
  font-family: "degular-display", sans-serif;
}

body #root {
  overflow: hidden;
}

body a,
body button {
  cursor: pointer;
  font-family: "degular-display", sans-serif;
}

body h1,
body h2,
body h3,
body h4,
body h5,
body p {
  font-weight: 400;
  margin: 0;
  line-height: 1;
}

.rootContainer {
  width: 100%;
  overscroll-behavior: none;
}

/* section one */

.frameHide {
  display: none;
}

.sectionOne {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.map,
.wine {
  z-index: -1;
  position: absolute;
}

.map {
  width: 100%;
  height: auto;
  bottom: 40px;
}

.wine {
  right: 0;
  top: unset;
  height: 100%;
  bottom: -215px;
  width: 250%;
  left: -125%;
  transform: translate(17%, 0);
  width: 320%;
  left: -164%;
  height: 110%;
}

.textContainer {
  width: 100%;
  padding: 35px 20px;
  text-align: center;
  box-sizing: border-box;
}

.startContainer h1 {
  font-size: 32px;
  padding-top: 40px;
  line-height: 1;
  text-align: center;
  margin: 0;
}

.year {
  font-size: 140px;
  color: #c5aa5f;
  font-weight: bold;
  line-height: 1;
}

.percent {
  max-width: 300px;
  font-size: 37px;
  margin: 0;
  line-height: 1;
  display: flex;
  white-space: pre;
}

.percent .asterik {
  font-size: 20px;
}

.statisticsContainer {
  opacity: 0;
  position: absolute;
  top: 15%;
  left: 50%;
}

.eyebrowContainer {
  display: flex;
  justify-content: center;
}

.infoContainer {
  width: 170px;
  align-self: center;
  position: relative;
  height: 56px;
  margin-left: 10px;
  text-align: left;
}

.infoContainer h4 {
  position: absolute;
  font-size: 16px;
}

/* section two */

.sectionTwo {
  background-color: #ffffff;
  z-index: -1;
  position: relative;
}

.missionContainer {
  padding: 120px 30px 70px;
  box-sizing: border-box;
  position: relative;
  height: 100%;
  z-index: 1;
}

.missionTexture {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
  max-width: 800px;
  height: 100%;
  object-fit: cover;
}

.textOne {
  font-size: 40px;
  line-height: 1;
  margin: 0;
  transform: translate(0px, 100px);
  opacity: 0;
}

.textTwo {
  font-size: 40px;
  text-align: right;
  opacity: 0;
  margin: 40px 0 0 auto;
  transform: translate(0px, 100px);
}

.textThree {
  font-size: 20px;
  transform: translate(0px, 60px);
  opacity: 0;
  margin: 50px 0 0;
  line-height: 1.2;
}


/* section three */
.sectionThree {
  height: 100vh;
}

.closeButton {
  position: absolute;
  z-index: 2;
}

.closeButton svg {
  width: inherit;
  height: inherit;
}

.closeButton svg:hover circle {
  fill: #c1aa6a;
  cursor: pointer;
}

.closeButton svg:hover path {
  stroke: #f7f8f9;
}

.sectionFour{
  background-color: #ffffff;
}


@media screen and (min-width: 768px) {

  .statisticsContainer {
    top: 50%;
    left: unset;
  }

  /* section 1 */

  .sectionOne {
    justify-content: center;
  }

  .map {
    width: 50%;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
  }

  .wine {
    height: 130%;
    right: -38%;
    top: -65px;
    left: unset;
    width: auto;
    bottom: unset;
    transform: unset;
  }


  .startContainer h1 {
    font-size: 70px;
    text-align: left;
    padding: 0;
    font-size: 4.3em;
  }

  .textContainer {
    width: 830px;
    padding: 70px;
    text-align: left;

    max-width: 52%;
    padding-right: 0;
  }

  .year {
    font-size: 250px;
  }

  .percent {
    font-size: 60px;
  }

  .infoContainer {
    width: 300px;
  }

  .infoContainer h4 {
    font-size: 24px;
  }

  .eyebrowContainer {
    justify-content: normal;
  }

  .cursorWrapper{
    height: 200px;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .cursor{
    position: absolute;
    border: 1px solid  #c5aa5f;
    height: 60px;
    width: 40px;
    border-radius: 20px;
    bottom: 0;
    display: flex;
    align-items: center;
    place-content: center;
  }

  .cursor.hide{
    display: none;
  }

  .cursorLine {
    overflow: hidden;
    width: 1px;
    height: 50%;
    border-radius: 50px;
}

.innerLine{
    width: 100%;
    height: 100%;
    background-color: #c5aa5f;
}
  /* section 2 */

  .missionContainer {
    padding: 120px;
  }

  .textOne {
    font-size: 50px;
    width: 400px;
  }

  .textTwo {
    font-size: 50px;
    width: 500px;
    margin-top: 40px;
  }

  .textThree {
    font-size: 26px;
    width: 500px;
  }
}

@media screen and (min-width: 1200px) {

/* section one */
  .year {
    font-size: 300px;
  }

  .percent {
    font-size: 70px;
  }

  .infoContainer h4 {
    font-size: 28px;
  }


  /* section 2 */
  .textOne {
    font-size: 88px;
    width: 750px;
  }

  .textTwo {
    font-size: 88px;
    width: 1000px;
    margin-top: 65px;
  }

  .textThree {
    font-size: 32px;
    width: 680px;
    margin-top: 50px;
  }
}
