.footer {
    background-color: #12100F;
    color: #EDEBEA;
    width: 100%;
    z-index: 3;
    position: relative;
}

.footer a {
    color: #EDEBEA;
    font-size: 14px;
    margin-right: 20px;
}

.footer .footerTextRight a{
    margin-right: 0;
}

.footer p {
    font-size: 14px;
    margin-bottom: 10px;
}

.footerSvg {
    width: 120px;
    align-self: center;
    margin-bottom: 10px;
}

.footerWrapper {
    padding: 30px;
    display: flex;
    flex-direction: column;
}

.footerLinks {
    margin-bottom: 10px;
}

@media screen and (min-width: 768px) {

    .footerSvg {
        width: 170px;
        margin: unset;
    }

    .footer p,
    .footer a {
        font-size: 20px;
    }

    .footerWrapper {
        padding: 60px;
        justify-content: space-between;
        flex-direction: row;
    }

    .footerText {
        display: flex;
    }

    .footerTextLeft {
        margin: 0 30px;
        max-width: 470px;
    }

    .footerTextRight {
        max-width: 370px;
    }
}