.join {
    font-size: 40px;
    line-height: 1;
    margin: 0;
    padding: 30px;
    transform: translate(0px, 100px);
    opacity: 0;
    max-width: 300px;
}

.linkContainer {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    padding: 20px 30px;
    transform: translate(0px, 100px);
    opacity: 0;
}

@media screen and (min-width: 768px) {

    .contentWrapper {
        padding: 120px 120px 0;
    }

    .join {
        font-size: 50px;
        max-width: 470px;
        padding: 40px 0;
    }

    .linkContainer {
        padding: 80px 0;
        max-width: 1000px;
    }

}

@media screen and (min-width: 1024px) {

    .join {
        font-size: 88px;
        max-width: 730px;
    }

}