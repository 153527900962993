.background {
    height: inherit;
}

.backgroundVideo{
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -4;
    position: relative;
}

.backgroundImage {
    background-image: url("../../assets/winery.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: inherit;
}

.backgroundImage.blur {
    filter: blur(20px);
    -webkit-filter: blur(20px);
    transform: scale(1.1);
}

.lightbox {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.box {
    box-sizing: border-box;
    padding: 35px;
    background-color: #fff;
    width: 85%;
    height: calc(100vh - 200px);
    max-height: 850px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.wrapper::-webkit-scrollbar {
    width: 5px;
}

.wrapper::-webkit-scrollbar-track {
        margin-top: 30px;
}

.wrapper::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 5px;
}

.box .title {
    color: #c5aa5f;
    text-align: center;
    margin: 0 auto;
    max-width: 380px;
    font-size: 40px;
}

.box button {
    background-color: #c5aa5f;
    color: #fff;
    width: 200px;
    border-radius: 50px;
    height: 50px;
    font-size: 20px;
    border: none;
}

.box .closeButton {
    right: 0;
    top: 0;
}

.box .closeButton svg {
    transform: scale(0.5);
}

.box.hide {
    display: none;
}


.box.show {
    box-sizing: border-box;
    background-color: #fff;
    justify-content: flex-start;
}

.wrapper {
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
}

.box.bg {
    background-color: #312f2b;
    overflow: hidden;
}

.lightboxSVGContainer {
    width: 100%;
    text-align: center;
    flex-grow: 1;
}

.svg {
    max-width: 145px;
    height: 100%;
}

.box.bg .svg {
    max-width: 230px;
    width: inherit;
}

.box h2 {
    color: #c5aa5f;
}

.box .wrapper p {
    margin: 10px 0;
}

.gallery {
    flex-direction: column;
    display: grid;
    gap: 8px;
    padding: 10px 0;
}

.galleryRow1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
}


.galleryRow1 img, 
.galleryRow2 img {
    width: 100%;
}

.bgTexture {
    position: absolute;
    top: 0;
    z-index: -1;
    left: 0;
}

@media screen and (min-width: 768px) {
    .box {
        padding: 55px;
        width: 600px;
        height: 580px;
        max-height: calc(100% - 20px);
    }

    .box .title {
        max-width: unset;
        font-size: 50px;
    }

    .box button {
        width: 350px;
        height: 60px;
        font-size: 26px;
    }

    .svg {
        max-width: 180px;
    }
    
    .box.bg .svg {
        max-width: 450px;
    }

    .box h2 {
        font-size: 50px;
    }

    .box .wrapper p {
        font-size: 26px;
        margin: 30px 0;
    }

    .box .closeButton {
        right: 12px;
        top: 12px;
    }

    .box .closeButton svg {
        transform: scale(0.70);
    }

    .gallery {
        padding: 35px 0 0;
    }

    .gallery,
    .galleryRow1 {
        gap: 15px;
    }
}